<template>
  <div class="work-block">
    <menu-breadcrumb>
      <el-breadcrumb-item>作品管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          作品列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <div>
          <el-button
            type="success"
            @click="$router.push({ name: 'CreateWork' })"
            >新增作品
          </el-button>
        </div>
      </table-title>

      <el-table :data="work_data">
        <el-table-column
          label="索引"
          prop="index"
          width="80px"
          sortable
        ></el-table-column>
        <el-table-column label="啟用" prop="active" width="80" sortable>
          <template v-slot="{ row }">
            <el-checkbox
              :value="row.active"
              @change="handleActive(row.work_id)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="ID" prop="work_id" sortable></el-table-column>
        <el-table-column
          label="中文標題"
          prop="work_zh.title"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文標題"
          prop="work_en.title"
          sortable
        ></el-table-column>
        <el-table-column label="創建時間" prop="created_at" sortable>
          <template v-slot="{ row }">
            {{ $formatTime(row["created_at"]) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="260">
          <template v-slot:header="{}">
            <el-input
              v-model="setup.search"
              size="mini"
              placeholder="搜尋作品標題"
              @keyup.enter.native="handleSearch"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
          </template>

          <template v-slot="{ row }">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-setting"
              @click="handleRouter('DetailWork', row.work_id)"
              >內容</el-button
            >
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="handleRouter('EditWork', row.work_id)"
              >編輯</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(row.work_id)"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import { deleteWork, getWorksPagination, updateWork } from "@/api/work";

export default {
  name: "Work",
  data() {
    return {
      work_data: [],
      total: 0,
      setup: {
        page: 0,
        limit: 25,
        search: "",
      },
    };
  },
  created() {
    const { p, s } = this.$route.query;
    if (p) {
      this.setup.page = +p;
    }
    if (s) {
      this.setup.search = decodeURIComponent(s);
    }
    this.handleGetWorks();
  },
  methods: {
    async handleGetWorks() {
      const { results, total } = await getWorksPagination(this.setup);

      this.work_data = results;
      this.total = total;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetWorks();
    },
    async handleSearch() {
      this.setup.page = 0;

      let query = {
        p: this.setup.page,
        s: encodeURIComponent(this.setup.search),
      };

      if (decodeURIComponent(query.s).length === 0) delete query.s;

      await this.$router.push({ query });

      await this.handleGetWorks();
    },
    async handleActive(workId) {
      const index = this.work_data.findIndex(
        ({ work_id }) => work_id === workId
      );

      if (index !== -1) {
        this.work_data[index].active = !this.work_data[index].active;

        await updateWork(workId, this.work_data[index]);

        this.$message({
          type: "success",
          message: "更新成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "更新失敗",
        });
      }
    },
    handleDelete(work_id) {
      this.$confirm("是否刪除該作品", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteWork(work_id);

          this.$message({
            type: "success",
            message: "刪除成功",
          });

          await this.handleGetWorks();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
    handleRouter(name, work_id) {
      this.$router.push({
        name,
        params: { id: work_id },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
